import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalSpinnerService {
  globalSpinnerSignal = signal<boolean>(false);

  constructor() { }

  showSpinner(): void {
    this.globalSpinnerSignal.set(true);
  }

  hideSpinner(): void {
    this.globalSpinnerSignal.set(false);
  }

  isSpinnerVisible(): boolean {
    return this.globalSpinnerSignal();
  }
}
